<template>
  <div>
    <h2>Template Selection: <span class=" font-normal">Step 2</span></h2>
    <p>Select a template to work with</p>
    <div>
      <input v-model="input" placeholder="Search for Template..." class="wizard__input w-100" />
    </div>
    <div class="my-3">
      <p>Select your preferred canvas size</p>

      <div v-if="!templates.length">
        <i>No Templates available</i>
      </div>

      <div v-else class="wizard__grid_3">
        <label
          v-for="(item, idx) in templates"
          :class="{ 'px-3 wizard__grid_item': true, active: canvas?.id === template?.id }"
          :key="idx"
        >
          <video :source="item.src" />
        </label>
      </div>
    </div>
    <div>
      <router-link class="btn btn-primary" :to="{ name: 'video-composer-wizard', query: { step: 3 } }">
        Next
      </router-link>
      <router-link class="btn" :to="{ name: 'video-composer-wizard', query: { step: 1 } }">
        Previous
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Step2',
  data: () => ({
    templates: [],
    canvas: null,
    template: null,
    input: undefined,
  }),
  computed: {
    selected: {
      get() {
        return this.$store.state.videoGenerator.template;
      },
      set(value) {
        this.$store.commit('videoGenerator/UPDATE_TEMPLATE', value);
      },
    },
  },
};
</script>
<style lang="scss">
@import '~@/scss/variables';
.hidden {
  display: none;
}
.wizard {
  &__grid_3 {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-row: 1fr;
    max-height: 400px;
    overflow-y: auto;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__grid_item {
    height: 150px;
    border-radius: 4px;
    background-color: rgba($color: $primary, $alpha: 1);
    border: 1px solid $primary;
    padding: 10px;
    color: white;
    margin-bottom: 0;
    transition: all 0.3s;

    &.active {
      border: 5px solid black;
      background: $secondary;
      color: $primary !important;
    }
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
