<template>
  <div>
    <h2><b>Step 3:</b> Generate Video Script</h2>
    <p>You can choose to generate your script with AI or by your self</p>
    <div class="wizard__selection mb-3">
      <label :class="['btn', scriptType === 'ai' && 'active']">
        Generate With AI
        <input type="radio" value="ai" v-model="scriptType" name="scripttype" />
      </label>
      <label :class="['btn', scriptType === 'manual' && 'active']">
        Create Your script
        <input type="radio" value="manual" v-model="scriptType" name="scripttype" />
      </label>
    </div>

    <div v-if="scriptType === 'ai'" class="wizard__ai_scriptor mb-3">
      <div class="flex flex-row items-center justify-content-between mb-3">
        <h4 class="mb-0">AI Script Generator</h4>
        <button
          @click="generateScriptsWithAi"
          :disabled="generatingScripts"
          class="btn flex items-center py-1 btn-secondary"
        >
          <div
            class="spinner-border text-primary"
            style="width: 1rem; height: 1rem;"
            v-if="generatingScripts"
            role="status"
          ></div>
          Generate
        </button>
      </div>
      <textarea
        v-model="prompt"
        rows="4"
        placeholder="Please enter the description of the type of script you want"
        class="wizard__input rounded w-100"
      ></textarea>
    </div>

    <div class="wizard__scripts">
      <div
        :key="idx"
        v-for="(_, idx) in scripts"
        @dragstart="dragStart(idx)"
        @dragover.prevent
        @drop="drop(idx)"
        :draggable="scripts.length > 2"
        class="wizard__entry"
      >
        <div class=" w-100">
          <div class="px-4 pt-3 w-100">
            <input
              class="mb-2 w-100 px-2 form-control py-0 rounded"
              v-model="scripts[idx].description"
              placeholder="Enter description..."
            />
            <textarea
              v-model="scripts[idx].displaytext"
              placeholder="Enter Script"
              rows="3"
              class="mb-0 py-0 form-control px-2 w-100 rounded"
            ></textarea>
          </div>
          <div class="flex px-4 flex-row py-2 items-center justify-content-between">
            <div :class="{ hidden: !(scripts.length !== idx + 1) }">
              <button class="btn btn-info btn-sm mr-2" v-show="scripts.length > 2">
                <i class="bi bi-arrows-move"></i> Move
              </button>
              <button @click="() => deleteScriptAt(idx)" class="btn btn-danger btn-sm">
                <i class="bi bi-x-lg"></i> Delete
              </button>
            </div>

            <div :class="{ hidden: scripts.length !== idx + 1, 'mb-2': true }">
              <button @click="scripts.push({})" class="btn btn-sm btn-success">
                <i class="bi bi-plus-lg"></i> Add More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-button @click="saveVideoGeneration" :disabled="loading" primary class="btn btn-primary">
      <b-spinner v-if="loading" sm label="Loading..."></b-spinner>
      Proceed to editor
    </b-button>
    <router-link class="btn" :to="{ name: 'video-composer-wizard', query: { step: 2 } }">
      Previous
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'VCStep3',
  data: () => ({
    prompt: undefined,
    dataKey: undefined,
    scriptType: 'manual',
    draggedItemIndex: null,
    generatingScripts: false,
  }),
  watch: {
    scriptType: {
      immediate: true,
      handler() {
        if (this.scriptType === 'manual') {
          this.scripts = [{ displaytext: '', description: '', voiceover: '' }];
        } else {
          this.scripts = [];
        }
      },
    },
  },
  computed: {
    scripts: {
      get() {
        return this.$store.state.videoGenerator.scripts;
      },
      set(value) {
        this.$store.state.videoGenerator.scripts = value;
      },
    },

    loading: {
      get() {
        return this.$store.state.videoGenerator.generatingScripts;
      },
      set(value) {
        this.$store.state.videoGenerator.generatingScripts = value;
      },
    },
  },
  methods: {
    async generateScriptsWithAi() {
      try {
        if (!this.prompt) return;
        this.generatingScripts = true;
        await this.$store.dispatch('videoGenerator/generateScripts', {
          message: this.prompt,
          count: 10,
        });
      } catch (error) {
        alert(error);
      } finally {
        this.generatingScripts = false;
      }
    },
    moveToEditor(key) {
      const VIDEO_EDITOR_URL =
        window.location.protocol === 'http:' ? 'https://videditor.test' : 'https://video.socialagency360.com';

      const viewlist = window.location.href.replace(
        'video-composer-editor', //
        'video-composer-list',
      );

      const navigateTo = new URL(VIDEO_EDITOR_URL);

      navigateTo.searchParams.append('key', key);
      navigateTo.searchParams.append('to', viewlist);
      navigateTo.searchParams.append('from', window.location.href);
      navigateTo.searchParams.append('baseurl', process.env.VUE_APP_API_ROOT);

      window.location.href = navigateTo.toString();
    },
    async saveVideoGeneration() {
      try {
        this.loading = true;
        const videoId = await this.$store.dispatch('videoGenerator/proceedToEditor');
        if (videoId) this.moveToEditor(videoId);
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },

    deleteScriptAt(idx) {
      this.scripts.splice(idx, 1);
    },
    dragStart(index) {
      this.draggedItemIndex = index;
    },
    drop(index) {
      const draggedItem = this.scripts[this.draggedItemIndex];
      this.scripts.splice(this.draggedItemIndex, 1);
      this.scripts.splice(index, 0, draggedItem);
      this.draggedItemIndex = null;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/scss/variables';

$checkbox_width: 20px;
$border-radius: 5px;
p {
  font-weight: 400;
}
.wizard {
  &__ai_scriptor {
    padding: 15px;
    background-color: $primary;
    border-radius: $border-radius;
    // color: white;

    h4 {
      font-size: 20px;
      color: white;
    }

    textarea {
      color: black;
      &::placeholder {
        color: rgba(245, 245, 245, 0.678);
      }
    }
  }
  &__scripts {
    margin-bottom: 15px;
    border-radius: $border-radius;
  }
  &__entry {
    display: flex;
    flex-direction: row;
    background: rgba(0, 128, 58, 0.185);
    border: 1px solid $primary;
    align-items: center;
    border-radius: $border-radius;
    margin-bottom: 10px;
  }
  &__selection {
    display: flex;
    gap: 10px;
    label {
      width: 100%;
      background-color: rgba(0, 128, 58, 0.185);
      display: block;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: 30px 10px;
      border: 1px solid;

      &.active {
        border: 4px solid;
      }

      > input[type='radio'] {
        display: none;
      }
    }
  }
}
</style>
