<template>
  <div class="relative">
    <button
      @click="show = !show"
      ref="btn"
      :style="{ appearance: 'none' }"
      class="btn btn-outline-primary wizard__input"
    >
      <div :style="{ width: '50px', minHeight: '30px', background: color?.style, borderRadius: '5px' }">&nbsp;</div>
    </button>
    <div
      class="absolute bg-white wizard__input mt-3"
      style="z-index: 2000; box-shadow: 0px 0px 0px solid red;"
      v-if="show"
    >
      <ColorPicker
        :color="color"
        :onStartChange="color => onChange(color, 'start')"
        :onChange="color => onChange(color, 'change')"
        :onEndChange="color => onChange(color, 'end')"
      />
    </div>
  </div>
</template>

<script>
import { ColorPicker } from 'vue-color-gradient-picker';

export default {
  name: 'App',

  components: {
    ColorPicker,
  },

  data() {
    return {
      show: false,
      color: {
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1,
      },
    };
  },

  methods: {
    onChange(attrs, _) {
      this.color = { ...attrs };
      this.$emit('input', this.rgbaToHex(attrs.red, attrs.green, attrs.blue, attrs.alpha));
      this.$refs.btn.click();
      this.$refs.btn.disabled = true;

      setTimeout(() => {
        this.$refs.btn.disabled = false;
      }, 500);
    },
    open() {
      this.show = true;
    },
    rgbaToHex(r, g, b, a) {
      const hex = ((r << 16) | (g << 8) | b).toString(16);
      return `#${hex.padStart(6, '0')}${
        a < 1
          ? `${Math.round(a * 255)
              .toString(16)
              .padStart(2, '0')}`
          : ''
      }`;
    },
  },
};
</script>

<style src="vue-color-gradient-picker/dist/index.css" lang="css" />
