<template>
  <div>
    <h2>Step 1</h2>
    <p>Give your video a preferred title</p>
    <div class="d-flex my-3 align-items-center">
      <CPicker v-model="color" />
      <span class="ml-3">
        Select your default background color
      </span>
    </div>
    <div class="mb-3">
      <input v-model="title" placeholder="Enter title" class="wizard__input w-100" />
    </div>

    <div class="mt-3">
      <p>Select your preferred canvas size</p>

      <div>
        <b-row>
          <b-col cols="4" v-for="(canva, idx) in presets" :key="idx">
            <label
              :class="{
                'px-3 w-100 wizard__input mb-4': true,
                active: canva.id === canvas_size?.id,
              }"
              :key="idx"
            >
              <p :style="{ fontWeight: '600' }" class="font-bold">{{ canva.name }} ({{ calculateRatio(canva) }})</p>
              <div class="preset-wrapper p-3">
                <div class="canvas-image">
                  <img :src="fakeCanvas(canva)" />
                </div>
              </div>
              <input type="radio" name="canvas_size" :value="canva" class="hidden" v-model="canvas_size" />
            </label>
          </b-col>
        </b-row>
      </div>
    </div>
    <router-link class="btn btn-primary mt-3" :to="{ name: 'video-composer-wizard', query: { step: 2 } }">
      Next
    </router-link>
  </div>
</template>
<script>
// import DSelect from './DSelect';
import CPicker from './CPicker';

export default {
  name: 'VCStep1',
  props: ['value'],
  components: { CPicker },
  data: () => ({
    presets: [
      {
        name: 'Dribbble shot',
        id: 'dribbble',
        width: 1600,
        height: 1200,
      },
      { name: 'Facebook post', id: 'facebook', width: 1280, height: 720 },
      {
        name: 'Facebook ad',
        id: 'facebook-ad',
        width: 1080,
        height: 1080,
      },
      { name: 'Youtube video', id: 'youtube', width: 1920, height: 1080 },
      {
        name: 'Instagram video',
        id: 'instagram-id',
        width: 1080,
        height: 1920,
      },
      {
        name: 'Instagram stories',
        id: 'instagram-stories',
        width: 1080,
        height: 1920,
      },
      { name: 'Twitter video', id: 'twitter', width: 1280, height: 720 },
      { name: 'Snapchat ad', id: 'snapchat', width: 1080, height: 1920 },
      {
        name: 'LinkedIn video',
        id: 'linkedin',
        width: 1920,
        height: 1080,
      },
      {
        name: 'Product Hunt thumbnail',
        id: 'product-hunt',
        width: 600,
        height: 600,
      },
      {
        name: 'Pinterest ad',
        id: 'pinterest',
        width: 1080,
        height: 1920,
      },
    ],
  }),
  computed: {
    title: {
      get() {
        return this.$store.state.videoGenerator.title;
      },
      set(value) {
        this.$store.commit('videoGenerator/UPDATE_TITLE', value);
      },
    },
    color: {
      get() {
        return this.$store.state.videoGenerator.background;
      },
      set(value) {
        console.log({ value });
        this.$store.commit('videoGenerator/UPDATE_BG', value);
      },
    },
    canvas_size: {
      get() {
        return this.$store.state.videoGenerator.canvas;
      },
      set(value) {
        this.$store.commit('videoGenerator/UPDATE_CANVAS', value);
      },
    },
    bpresets() {
      return this.presets.map(e => ({
        ...e,
        img: this.fakeCanvas(e),
        ratio: this.calculateRatio(e),
      }));
    },
  },
  methods: {
    onChange(attrs, _) {
      this.color = { ...attrs };
    },
    calculateRatio({ width, height }) {
      function gcd(a, b) {
        return b === 0 ? a : gcd(b, a % b);
      }

      const gcdValue = gcd(width, height);
      const simplifiedWidth = width / gcdValue;
      const simplifiedHeight = height / gcdValue;
      return `${simplifiedWidth}×${simplifiedHeight}`;
    },
    fakeCanvas({ width, height }) {
      const svgContent = `
        <svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="100%" fill="white"/>
        </svg>
      `;

      return 'data:image/svg+xml;base64,' + btoa(svgContent);
    },
  },
};
</script>
<style lang="scss">
@import '~@/scss/variables';
.hidden {
  display: none;
}
.preset-wrapper {
  background-color: var(---base-green);
  border-radius: 8px;
}
.canvas-image {
  width: 100%;
  height: 100px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.wizard__input.active {
  border: 5px solid black;
  background: $secondary;
  color: $primary !important;
  transition: all 0.3s;
}

.wizard {
  &__grid_3 {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-row: 1fr;
    max-height: 400px;
    overflow-y: auto;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__grid_item {
    height: 150px;
    border-radius: 4px;
    background-color: green;
    border: 1px solid $primary;
    padding: 10px;
    color: white;
    margin-bottom: 0;
    transition: all 0.3s;

    &.active {
      border: 5px solid black;
      background: $secondary;
      color: $primary !important;
    }
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
